import { getI18n } from "react-i18next";
import {
	FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig,
	FxIdDomainSettingsPublicWebClientConfigOptionsLegalContact,
	type FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue,
	FxIdDomainSettingsUrlOpenMethod,
	FxIdWebFeaturesPlayPublicDataBase
} from "../../Api/gen";
import { IframeType } from "../../Screens/WebPlayer/Hooks";

// Это через жопу. Надо настройками передавать
const EXISTING_ADAPTERS_MAP = new Map([
	["fxid", true],
	["ok", true],
	["yandex", true]
]);

export function getDocUrl(params: { docFile: string; adapter?: string | null }) {
	const adapterParam = params.adapter ?? "fxid";
	const docsPath = `${window.location.origin}${import.meta.env.BASE_URL}/docs`;
	const lang = getI18n().language === "ru" ? "ru" : "en";
	const adapter = EXISTING_ADAPTERS_MAP.get(adapterParam) ? adapterParam : "fxid";
	return new URL(`${docsPath}/${adapter}/${lang}/${params.docFile}`);
}

export type IDocsData = {
	url: URL | string;
	method: FxIdDomainSettingsUrlOpenMethod;
} | null;

export type DocsFromPublicConfigAndBranding = {
	tos: IDocsData;
	pp: IDocsData;
	legalContacts: { [key: string]: FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue } | null;
};

export const getDocsUrlFromGameConfigAndBranding = (
	gameConfigId?: string,
	gameConfigAdapter?: IframeType,
	contactsUrl?: string
) => (contactsUrl != null ? contactsUrl : `/docs/${gameConfigId}/${gameConfigAdapter}/contacts`);
